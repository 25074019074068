import React from "react";
import Layout from "../components/layout/Layout";

import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import * as animationData from "../assets/animations/404_animation.json";
import { ButtonSymbolHover } from "../components/Button";

export default ({ location }) => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Layout location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container has-text-centered is-hidden-mobile is-flex is-flex-direction-column is-align-items-center">
                            <Lottie
                                {...defaultOptions}
                                style={{width:840, height:650}}
                                play={true}
                            />
                            <p className="size-16 is-grey-1 margin-y-10">
                                It is very unprofessional for an IT company to<br /> ever show you this page. Yet… here we are.
                            </p>
                            <ButtonSymbolHover type="filled" to="/" text="go back home" color="blue" />
                        </div>
                        <div className="container has-text-centered is-hidden-tablet is-flex is-flex-direction-column is-align-items-center">
                            <Lottie
                                {...defaultOptions}
                                style={{width:300, height:300}}
                                play={true}
                            />
                            <p className="size-16 is-grey-1 margin-y-20">
                                It is very unprofessional for an IT company to<br /> ever show you this page. Yet… here we are.
                            </p>
                            <ButtonSymbolHover type="filled" to="/" text="go back home" color="blue" />
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}